export default {
  title: 'Workflows',
  new: 'New workflow',
  createNew: 'Create new',
  fromTemplate: 'From template',
  allTemplates: 'All templates',
  templates: 'Workflow templates',
  templatesTry: 'Try our workflow templates',
  addValue: 'add value',
  addReturn: 'add return',
  components: 'Components',
  connectionData: 'Connection data',
  observatory: 'Data Observatory',
  files: 'Files',
  doNotAvailableInRegion: 'This feature is not available for the selected region in this connection',
  connection: 'Connection',
  sources: 'Sources',
  empty: 'Drag & drop data sources or components from the left panel to get started',
  noResults: 'You don’t have any workflows yet. Create one to get started.',
  createFirst: 'Create your first workflow',
  canNotCreate: `Your current role doesn't allow creating workflows. Talk to your organization admin.`,
  settings: 'Settings',
  parametersNotAvailable: 'This component has been permanently removed and can no longer be used.',
  advancedOptions: 'Advanced options',
  aggregation: 'Aggregation',
  aggMethod: 'Aggregation method',
  aggValue: 'Aggregation value',
  columnType: 'Column type',
  removeAll: 'Remove all',
  remove: 'Remove',
  groupBy: 'Group by',
  expose: 'Expose',
  edit: 'Edit',
  direct: 'Direct',
  reverse: 'Reverse',
  editExposedParameter: 'Edit exposed parameter text',
  workflowUnexecuted: 'Workflow has not been executed yet',
  startExecution: 'Started execution of workflow',
  finishExecution: 'Workflow execution completed successfully',
  finishExecutionDetails: 'Duration: {duration}',
  canceledExecution: 'Workflow execution canceled',
  emptyQuery: 'Workflow produced an empty query',
  deleteEdge: 'Delete edge',
  deleteNode: 'Delete node',
  updateNode: 'Update node',
  lastExecution: 'Last run ',
  input: 'Input',
  output: 'Output',
  importing: 'Importing node, please wait to connect',
  inputMultiple: ' • Multiple',
  tableSummary: '{rows} ROWS <span>•</span> {cols} COLS.',
  shareDisabledTooltip: 'Give a title to this workflow before sharing it',
  demo: {
    tag: 'Demo'
  },
  sample: {
    overline: 'Need ideas?',
    title: 'Try our sample workflows'
  },
  errors: {
    error: 'Error',
    cancel: 'Cannot cancel workflow',
    fetchWorkflow: 'Cannot fetch workflow info',
    state: 'Error getting workflow state',
    save: 'Cannot save the workflow',
    run: 'Cannot launch the workflow',
    token: 'Cannot get workflow token',
    schema: 'Cannot get workflow schema',
    execution: 'Workflow executed with errors',
    connection:
      "This workflow was originally created with a connection that you don't have access to. Duplicate the workflow to replace the connection or contact your administrator to get access to that connection.",
    global: `We're sorry, but for some reason we can't load your workflow`,
    tempStorageState: 'Error getting the temporal storage state',
    tempStorageAccessCreate:
      'Workflows temporal storage does not exist, is not authorized, or can not be created. Please, contact your admin to grant the required permissions and reload the workflow. <a>More info</a>',
    tempStorageCreateTables:
      'Workflows temporal storage already exists, but the user cannot create tables. Please, contact your admin to grant the required permissions and reload the workflow. <a>More info</a>',
    tempStorageCleanUpSchedule:
      'Workflows clear cache configuration can not be scheduled. Please, contact your admin to grant the required permissions and reload the workflow. <a>More info</a>',
    fetchAnalyticsToolboxLocation: 'Cannot fetch analytics toolbox location',
    moreErrors: '+{count, plural, one {# more error} other {# more errors}}',
    fetchImportedFilesError: 'Error with imported file',
    createWorkflow: {
      title: 'There was an error while creating the workflow.',
      connectionExist: 'Please make sure the table and the CARTO connection still exist.',
      connectionAvailable: 'Please make sure the CARTO connection is still available.',
      general: 'Please try again later and if the error persists ',
      contact: 'contact us.'
    },
    dialog: {
      title: '{componentName} errors',
      message: 'The following errors were found in {componentName}:',
      close: 'Ok'
    }
  },
  cache: {
    title: 'Use cached results',
    description: {
      bigquery: `If enabled, Workflows will reuse results from previous executions. <link>Learn more</link>`,
      snowflake: `If enabled, Workflows will reuse results from previous executions. <link>Learn more</link>`,
      redshift: `If enabled, Workflows executed through API call will reuse results from previous executions. <link>Learn more</link>`,
      postgres: `If enabled, Workflows executed through API call will reuse results from previous executions. <link>Learn more</link>`
    }
  },
  alerts: {
    componentChangesDetected: 'Components Updated ({count}). Review modified components to enable workflow execution.'
  },
  loadingWorkflow: 'Loading workflow...',
  creatingDemoWorkflow: 'Creating demo workflow...',
  component: {
    description: 'Description',
    components: 'Components',
    inputParameters: 'Input Parameters',
    outputParameters: 'Output Parameters'
  },
  default: {
    title: 'Untitled'
  },
  navBar: {
    titleTooltip: 'Go to Workflows',
    title: 'CARTO Workflows',
    titleMobile: 'Workflows',
    updating: 'Autosaving workflow...',
    updated: 'Updated',
    share: 'Share',
    shareSharedTooltip: 'Shared workflow',
    sharePrivateTooltip: 'Private workflow',
    shareError: `There was an error sharing the workflow. Please try again and contact {link} if the error persists.`,
    shareWithGroups: {
      info: {
        notAvailable:
          "This workflow is shared with all users in your organization. If you're interested in sharing with certain users or groups, <link>let us know</link>."
      }
    }
  },
  shareDialog: {
    notShared: 'The workflow has not yet been shared with the selected groups',
    notSharedDescription: 'If you close the dialog now, the workflow will keep the current permission level.'
  },
  dialog: {
    title: 'Create new workflow',
    subtitle: 'Select the connection you want to work with:',
    helper:
      'The list of available components depends on each Data Warehouse. Learn more about workflows in our <link>Documentation</link>.',
    cancel: 'Cancel',
    create: 'Create workflow',
    noConnection: 'You have no connection available'
  },
  selectTemplateDialog: {
    title: 'Template Gallery',
    searchPlaceholder: 'Search template',
    emptyStateTitle: 'No templates were found',
    emptyStateContent: "Try adjusting your search to find what you're looking for."
  },
  cards: {
    actions: {
      edit: 'Edit workflow properties',
      share: 'Share',
      duplicate: 'Duplicate workflow',
      delete: 'Delete',
      editThisDemo: 'Edit this demo'
    },
    tooltips: {
      public: 'Public workflow',
      shared: 'Shared with Organization',
      private: 'Private workflow'
    },
    deleteDialog: {
      title: 'Delete workflow',
      description: 'Are you sure you want to delete {name}? This action cannot be undone.',
      delete: 'Yes, delete'
    },
    editDialog: {
      title: 'Edit workflow properties',
      inputs: {
        name: 'Name',
        description: 'Description'
      },
      buttons: {
        save: 'Save change'
      }
    }
  },
  componentSelector: {
    search: {
      placeholder: 'Search component',
      placeholderStarred: 'Search starred component',
      summary: '{nbMatching, plural, one {# component} other {# components}} by {searchTerm}',
      noResult: {
        header: 'No components were found',
        message: "Try adjusting your search to find the components you're looking for or",
        explore: 'Explore the whole components list',
        exploreStarred: 'Explore the whole starred list'
      },
      beyondStarred: {
        seeAll: 'See all results',
        resultsNotInStarred:
          '{notStarred, plural, one {# component} other {# components}} found beyond the starred list',
        moreResultsNotInStarred:
          '{notStarred, plural, one {# more component} other {# more components}} found beyond the starred list'
      }
    },
    viewMode: {
      tree: 'Tree view',
      grid: 'Grid view'
    },
    showAll: 'All components',
    showStarredOnly: 'Only starred',
    countOf: '{count} of {total}',
    disabledComponents: {
      'native.output': `It is not allowed to use more than one 'Output' component in the same workflow`
    }
  },
  tempLocationSelector: {
    menuTitle: 'Region used',
    orgRegion: "Your organization's region ({ region })",
    cartoRegion: "CARTO's region ({ region })",
    locked: "Switching between regions is only allowed when the Workflow's canvas is empty."
  },
  editor: {
    results: 'Results',
    enterFullscreen: 'Enter full screen',
    exitFullscreen: 'Exit full screen',
    collapse: 'Collapse',
    expand: 'Expand panel',
    connectionUsed: 'Connection used'
  },
  tabs: {
    unexecuted: 'Run the workflow to see results',
    data: {
      statsFrequency: 'Frequency of the top 20 categories.',
      tabName: 'Data',
      noSelectNode: 'To view this content, select a node in the canvas with a data preview',
      notAvailable: 'Data preview not available',
      rasterDataNotAvailable: "The content of raster tables can't be previewed",
      notAvailableDetailed: 'Please run the workflow to see the table preview for this node.',
      notAvailableOutput: 'This component does not generate any output.',
      showColumnStats: 'Show column stats',
      hideColumnStats: 'Hide column stats',
      columnStats: 'Column stats',
      column: 'Column',
      statisticsNotAvailable: 'Statistics not available',
      tryAgain: 'Try it again later',
      notSupportedColumnType: 'Column type not supported',
      copyData: 'Copy data from current page ({from}-{to})',
      exportNodeData: 'Export data from node',
      error: `There is a problem showing this data preview. Please try the following:
          <ul>
            <li>There might be errors in previous steps. Please check that other nodes have been executed successfully. </li>
            <li>The temporal table for this node might have been deleted. Please try re-running the workflow. </li>
            <li>In filtering components, the 'Unmatch' result is only generated when connected to another component. Please check if that's the case. </li>
          </ul>`
    },
    map: {
      tabName: 'Map',
      noSelectNode: 'To view this content, select a node in the canvas with a map preview',
      notAvailable: 'Map preview not available for the selected node.',
      create: 'Create map',
      noGeomField: 'No geometry field found',
      previewBased: 'Preview based on',
      tilesetNotCreatedYet: "The tileset hasn't been generated yet."
    },
    sql: {
      tabName: 'SQL'
    },
    code: {
      tabName: 'Code'
    },
    messages: {
      tabName: 'Messages'
    }
  },
  messages: {
    state: {
      success: 'EXECUTED AND VALID',
      failure: 'EXECUTED BUT FAULTY',
      unexecuted: 'NOT EXECUTED',
      unexecutable: 'NOT EXECUTABLE',
      running: 'RUNNING'
    },
    outdated:
      'This component is outdated and it requires manual configuration. Update and configure the component to allow running this workflow again.',
    deprecated: `This component has been deprecated and can't be used anymore. Delete this node to allow running this workflow again.`,
    removed: `This component has been removed and can't be used anymore. Delete this node and substitute the component by an equivalent one to allow running this workflow again.`
  },
  nodes: {
    sources: {
      sample: 'sample',
      records: 'records',
      notAvailable: 'not available'
    },
    sidebar: {
      commons: {
        details: 'Details',
        type: 'Type',
        provider: 'Provider',
        category: 'Category',
        rows: 'Rows',
        blocks: 'Blocks',
        blockSize: 'Block size',
        pixels: 'Pixels',
        center: 'Center',
        bounds: 'Bounds',
        bands: 'Bands',
        size: 'Size',
        lastUpdate: 'Last update',
        table: 'Table',
        raster: 'Raster',
        geomType: 'Geo column data type',
        sourceUrl: 'Source URL',
        columns: 'Columns',
        types: {
          sample: 'Sample dataset',
          public: 'Public dataset',
          premium: 'Premium dataset'
        },
        categories: {
          geography: 'Geography',
          dataset: 'Dataset'
        },
        show: {
          data: 'Show data table',
          map: 'Show map'
        }
      }
    }
  },
  runBar: {
    unexecutable: 'This workflow cannot be executed check the status of your components',
    updateNeeded: 'Review pending changes in components to allow running this workflow again.',
    addGenericNode: 'Add a component to the canvas to enable workflow execution'
  },
  scheduling: {
    schedule: 'Schedule Workflow',
    button: {
      workflowMustRun: 'Run this workflow to enable scheduling options',
      unavailableOnConnection: 'Scheduling workflows is not possible with the current connection',
      unavailableOnRedshift: 'Scheduling options are not available for Redshift connections',
      unavailableOnBigQueryOAuth:
        'Scheduling options for BigQuery OAuth connections require the version info code. Please, go to your connection to complete the configuration'
    },
    dialog: {
      editTitle: 'Edit scheduled workflow',
      desc: 'Schedule this workflow to run at regular intervals.',
      utcHelper:
        'The workflow schedule is based on the global UTC time standard. Consider the time difference between your region and UTC.',
      frequency: 'Repeat frequency',
      repeatsEvery: 'Repeats every',
      expression: 'Expression',
      expressionPlaceholder: {
        bigquery: 'e.g. every Mon 10:00',
        snowflake: 'e.g. 0 0 * * *',
        postgres: 'e.g. 0 0 * * *'
      },
      expressionDoc: 'Learn more in our <a>Documentation</a>',
      time: 'At',
      utc: 'UTC',
      delete: 'Delete schedule',
      days: 'days',
      hours: 'hours',
      selectDays: 'Select days',
      onThe: 'on the',
      deleteDialog: {
        title: 'Delete schedule',
        message: 'Are you sure you want to delete the schedule? This action cannot be undone.',
        confirm: 'Yes, delete',
        cancel: 'Cancel'
      },
      errors: {
        hours: 'Enter a valid number of hours.',
        time: 'Enter a valid time in HH:MM format.',
        weekDay: 'Select at least 1 day of the week.',
        monthDay: 'Select at least 1 day of the month.',
        invalid: 'The specified schedule is invalid.'
      },
      sync: {
        title: 'Sync latest changes',
        message: 'Your scheduled workflow is not up to date with the latest changes.',
        button: 'Sync',
        ok: 'Got it',
        success: 'Your scheduled workflow is in sync'
      }
    },
    frequencyHours: 'Hours',
    frequencyDays: 'Days',
    frequencyWeeks: 'Weeks',
    frequencyMonths: 'Months',
    frequencyCustom: 'Custom',
    invalid: {
      hourly: 'Every...',
      daily: 'Every day...',
      weekly: 'Weekly...',
      monthly: 'Monthly...'
    },
    summary: {
      hourly: 'Every {hours, plural, one {hour} other {# hours}}',
      daily: 'Every {days, plural, one {day} other {# days}} at {time} UTC',
      weekly: 'Weekly on {days} at {time} UTC',
      monthly: 'Monthly on the {days} at {time} UTC'
    }
  },
  parameterForm: {
    selectAField: 'Select a field',
    noColumns: 'There are no available columns',
    noCustomFeatures: 'No custom features added yet',
    addVariables: 'Add variables',
    selectColumn: 'Select column',
    selectAColumn: 'Select a column',
    cannotSelect: 'Cannot select more fields',
    noField: 'No field added yet',
    noColumn: 'No column added yet',
    geoJsonView: 'GeoJSON view',
    drawFeatures: 'Draw features',
    noConditions: 'No conditions added yet',
    selectColumnCondition: 'Select a column to define its conditions',
    noAvailableSource: 'Connect the component',
    when: 'When',
    addCondition: 'Add condition',
    returnValue: 'add return value',
    returnColumn: 'E.g.: return_column',
    originalType: 'Original type',
    typeCannotBeChanged: 'Casting is not allowed for this type',
    selectCondition: 'Select a condition type',
    isNull: 'is null',
    isTrue: 'is true',
    isFalse: 'is false',
    elseHelper: 'This value applies if none of the defined conditions are met',
    addConditionFor: 'Add condition for "{column}"',
    addValuePlaceholder: 'add value',
    returnValuePlaceholder: 'return value',
    tileFeatureOrderHelper:
      'SQL expression that controls the ordering of features per tile. Features that come before in the order are less likely to be dropped when any of the limits are reached.',
    zoomMinColHelper:
      'Select a column that contains the minimum zoom level at which the feature can be included in the tile.',
    zoomMaxColHelper:
      'Select a column that contains the maximum zoom level at which the feature can be included in the tile.',
    h3ResolutionHelper: 'The actual H3 resolution of the cells in your input data.',
    h3ResolutionMinHelper: 'The minimum H3 resolution at which the tileset will be generated.',
    h3ResolutionMaxHelper: 'The maximum H3 resolution at which the tileset will be generated.',
    h3AggregationResolutionHelper: 'This setting controls the granularity of the aggregation.',
    quadbinResolutionHelper: 'The actual Quadbin resolution of the cells in your input data.',
    quadbinResolutionMinHelper: 'The minimum Quadbin resolution at which the tileset will be generated.',
    quadbinResolutionMaxHelper: 'The maximum Quadbin resolution at which the tileset will be generated.',
    quadbinAggregationResolutionHelper: 'This setting controls the granularity of the aggregation.',
    pointAggAggregationResolutionHelper: 'This setting controls the granularity of the aggregation.',
    pointAggAggregationPlacementHelper:
      'This setting controls the placement of the geometry resulting from the aggregation.',
    pointAggZoomMinColHelper:
      'Select a column that contains the minimum zoom level at which the feature can be included in the tile.',
    pointAggZoomMaxColHelper:
      'Select a column that contains the maximum zoom level at which the feature can be included in the tile.',
    default: '(default)'
  },
  familyFilter: {
    placeholder: 'Component SQL language'
  },
  actions: {
    undo: 'Undo ({shortcut})',
    redo: 'Redo ({shortcut})',
    duplicate: 'Duplicate',
    delete: 'Delete'
  },
  notes: {
    title: 'Annotation',
    tip: 'Click/Drag to create a note',
    add: 'Add a Note',
    edit: 'Edit',
    readView: 'Read view',
    placeholder: 'Enter your text...',
    info: 'Markdown formatting supported. Learn more in this',
    doc: 'Documentation',
    colors: {
      yellow: 'Yellow',
      orange: 'Orange',
      purple: 'Purple',
      blue: 'Blue',
      pink: 'Pink',
      green: 'Green',
      brown: 'Brown',
      transparent: 'Transparent'
    },
    genAi: {
      add: 'Generate workflow description',
      select: 'Workflow description',
      regenerate: 'Regenerate',
      defaultPlaceholder: 'Click to generate a description',
      loadingPlaceholder: 'Generating content...'
    }
  },
  filters: {
    title: '{elements} results by "{query}"',
    search: 'Search workflow',
    noResults: 'No workflows were found',
    privacy: {
      all: 'All',
      myWorkflows: 'My workflows',
      shared: 'Organization'
    },
    order: {
      updatedAt: 'Last updated'
    },
    total: `{itemCount} {itemCount, plural,
      one {workflow}
      other {workflows}
    }`
  },
  preview: {
    toolbar: {
      viewerMessage: 'This workflow is in view mode.',
      editorNoAllowEdit:
        'This workflow is in view mode. If you want to inspect the components and edit theirs parameters, you can duplicate it.',
      keepPreview: 'Keep in view mode',
      duplicate: 'Duplicate workflow'
    }
  },
  duplicate: {
    title: 'Duplicate workflow',
    description: 'Select the connection you want to work with, as the connection used in the workflow is not available:'
  },
  export: {
    title: 'Export workflow',
    learnMore: 'Learn more in our Documentation',
    description1:
      'By exporting a workflow, an <strong>SQL script file</strong>, formatted as a Stored Procedure, will be automatically downloaded.',
    description2:
      'This file allows you to either save the Stored Procedure in your Data Warehouse or directly import the Workflow into the CARTO Platform.',
    success: 'Workflow exported successfully.'
  },
  import: {
    or: 'or',
    title: 'Import file into workflow',
    import: 'Import',
    imported: 'Imported',
    loadFile: 'Load file',
    delete: 'Delete',
    learnMore: 'Learn more',
    importFirst: 'Import one from a local file',
    importedFile: {
      snackMsg: '"{filename}" is added to Files.'
    },
    noImportedFiles: {
      title: 'No files found',
      description: "You haven't uploaded any files to this workflow yet."
    },
    tooltips: {
      importFile: 'Import file into workflow',
      tip: 'Click to import file'
    },
    dialog: {
      title: 'Import workflow',
      sourceUrl: 'Source URL',
      sourceUrlPlaceholder: 'E.g.: https://publicdomain.com/sources/myfile.csv'
    },
    deleteDialog: {
      title: 'Delete file',
      subtitle: 'Do you want to delete "{filename}" from Files?',
      description: 'This action cannot be undone. "{filename}" file will no longer be accesible in this workflow.',
      delete: 'Yes, delete',
      snackMsg: '"{filename}" has been deleted.'
    },
    tabs: {
      fromComputer: 'From your computer',
      fromUrl: 'From URL'
    },
    fileUpload: {
      title: 'File'
    },
    sourceUrl: {
      title: 'Source URL',
      placeholder: 'E.g.: https://publicdomain.com/sources/myfile.csv'
    },
    autoGuessDescription:
      'By default, CARTO automatically detect column data types. To set all columns as STRING, disable this option.',
    errors: {
      invalidFileType: 'Invalid file type.',
      filesLimit: '1 file can be imported at a time.'
    }
  },
  api: {
    menu: 'API',
    title: 'Workflow API endpoint',
    description:
      'By enabling API access to your workflow, you allow external systems to directly execute it or retrieve its results. This amplifies the utility of CARTO Workflows, connecting its powerful spatial tools with a broader range of external applications.',
    hint: 'This API endpoint enables external systems to execute or retrieve the results of this workflow.',
    apiUrl: 'API URL',
    curlApiCall: 'Example of API call using cURL',
    enabled: 'API enabled',
    lastUpdate: 'Last update ',
    docsUrl: 'Learn more in our <a>Documentation</a>',
    alerts: {
      upToDate: 'API endpoint is up to date',
      outdated: "The API hasn't been updated to match the latest Workflow modifications",
      delete: 'Are you sure you want to disable the API access? This action cannot be undone.'
    },
    messages: {
      created: 'API endpoint created successfully.'
    },
    actions: {
      learnMore: 'Learn more in our Documentation',
      enable: 'Enable API access',
      delete: 'Disable API access',
      confirmDelete: 'Yes, disable',
      update: 'Update'
    }
  },
  variables: {
    title: 'Variables',
    description: "List of variables that can be used as settings in this workflow's components.",
    hint: 'Learn more about variables in our <docUrl>Documentation</docUrl>',
    onboarding: {
      title: 'Tailoring your Workflows',
      subDescription: "Variables enhance the flexibility of Workflows' component settings",
      description:
        "To define a variable, specify a name, select its data type, and mark as <i>Parameter</i> to allow external calls. Once established, the variable can be referenced within component settings by placing its name between double curly braces, preceded by an '@' symbol, denoted as <strong>@variable</strong>.",
      notShow: "Don't show me again"
    },
    empty: {
      title: 'No variable created yet',
      description: "Create variables that can be used as settings on this workflow's components.",
      cta: 'Add variables'
    },
    form: {
      order: 'Order',
      name: 'Name',
      type: 'Type',
      typePlaceholder: 'Select...',
      defaultValue: 'Default value',
      parameter: 'Parameter',
      placeholders: {
        name: 'type_a_name',
        type: 'Select...',
        defaultValue: 'Define a default value'
      },
      tooltips: {
        parameter: {
          info: 'Once created, activate variable as parameter for external access.',
          enable: 'Activate variable as parameter for external access.'
        }
      },
      actions: {
        done: 'Done',
        remove: 'Remove',
        create: 'Create variable'
      },
      errors: {
        create: 'Complete the necessary fields correctly to create a variable.',
        required: 'No value assigned.',
        unique: 'This variable already exists.',
        numeric: 'The value should be numeric.'
      },
      messages: {
        created: 'Variable created successfully.',
        removed: 'Variable removed successfully.',
        edited: 'Variable edited successfully.',
        setVariableAsParameter: 'Variable set as parameter',
        setVariableAsNotParameter: 'Variable unset as parameter'
      },
      options: {
        string: 'String',
        number: 'Number'
      },
      discardEdit: {
        title: 'The variable has not been edited',
        description: 'If you close the dialog now, the changes will not be applied.'
      },
      discardCreate: {
        title: 'The variable has not been created',
        description: 'If you close the dialog now, the variable you defined will not be created.'
      }
    }
  },
  analyticsToolbox: {
    componentTreeInfo: {
      install: 'Install',
      learnMore: 'Learn more',
      message:
        'Some components ({nbComponents}) will not work properly because the chosen {connection} connection does not have the Analytics Toolbox available.'
    },
    componentWarning: {
      message:
        'This component requires the Analytics Toolbox and it is not available in the chosen {connection} connection.',
      install: 'Install Analytics Toolbox'
    }
  },
  outputTable: {
    clear: 'Clear selection',
    saveHere: 'Save here',
    saveChanges: 'Save changes',
    tableDetails: 'Table details',
    edit: 'Edit'
  },
  loginPrompt: {
    title: "Oops! You can't access this workflow",
    content:
      "Use your CARTO credentials or sign up to access this workflow. If the workflow exists and is shared with you, you'll be automatically redirected to it."
  },
  noPermissionsPrompt: {
    title: "Oops! You can't access this workflow",
    content:
      'It looks like this workflow is currently not shared with you. Please contact the workflow owner if you want to get access.'
  },
  caseWhen: {
    Equal: 'Is equal to...',
    NotEqual: 'Is not equal to...',
    GreaterThan: 'Is greater than...',
    GreaterThanOrEqual: 'Is greater than or equal to...',
    LessThan: 'Is less than...',
    LessThanOrEqual: 'Is less than or equal to...',
    Null: 'Is null',
    Contains: 'Contains...',
    True: 'Is true',
    False: 'Is false',
    tooltips: {
      when: 'When "{column}" is {condition}...',
      whenValue: 'When "{column}" is {condition} "{value}"',
      whenNull: 'When "{column}" is null',
      whenContains: 'When "{column}" contains...',
      whenContainsValue: 'When "{column}" contains "{value}"',
      whenFalse: 'When "{column}" is false',
      whenTrue: 'When "{column}" is true',
      then: 'then...',
      thenReturn: 'then "{return}"'
    },
    conditions: {
      equal: 'equal to',
      notEqual: 'not equal to',
      greaterThan: 'greater than',
      greaterThanOrEqual: 'greater than or equal to',
      lessThan: 'less than',
      lessThanOrEqual: 'less than or equal to',
      null: 'null',
      contains: 'contains',
      true: 'true',
      false: 'false'
    }
  },
  options: {
    center: 'Center',
    intersects: 'Intersects',
    contains: 'Contains'
  },
  banners: {
    geoJson: {
      title: 'Want to draw features?',
      description:
        'Use the new "Draw Custom Features" component to draw points, polygons, lines, rectangles, and circles.'
    }
  },
  extension: {
    tabs: {
      explore: 'Explore',
      installed: 'Installed',
      upload: 'Upload'
    },
    upload: {
      cta: 'Upload extension',
      description: 'Upload an extension package as a ZIP file',
      documentation: 'Check the <a>documentation</a> to get started'
    },
    table: {
      extension: 'Extension package',
      industry: 'Industry',
      version: 'Version',
      license: 'License',
      lastUpdate: 'Last update',
      author: 'Author'
    },
    actions: {
      seeDetails: 'See extension details',
      update: 'Update extension package',
      showInPanel: 'Show in components panel',
      show: 'Show in library',
      uninstall: 'Uninstall'
    },
    empty: "You haven't installed any extensions yet.",
    emptyPlugins: 'There are no plugins available.',
    title: 'Extension packages',
    version: 'Version',
    installed: 'Installed',
    outdated: 'Outdated',
    manage: 'Manage Extension Packages',
    uninstall: 'Uninstall extension',
    install: 'Install extension',
    details: 'Details',
    lastUpdate: 'Last update',
    errors: {
      install: "We couldn't install the extension package",
      installDetails:
        'The installation of the extension package has failed, please try again and contact Support if the problem persists',
      uninstall: 'Uninstallation error',
      uninstallDetails: 'The uninstallation of package extension has failed.'
    },
    dialogs: {
      install: {
        title: 'Installing extension package',
        description: 'This process might take a bit to complete.',
        extensionName: 'Extension package {name}',
        installing: 'Installing...'
      },
      update: {
        title: 'Updating extension package',
        updating: 'Updating...'
      },
      uninstallConfirmation: {
        headerTitle: 'Uninstall extension package',
        title: 'Do you want to uninstall the {name} package?',
        description:
          'This may break the current workflow and the workflows created by other users under the same connection.',
        confirmation: 'Yes, uninstall',
        uninstalling: 'Uninstalling...'
      }
    },
    messages: {
      installed: '{name} successfully installed.',
      uninstalled: '{name} has been uninstalled.'
    },
    permissions: {
      missingInstall: 'Please contact your administrator to enable the installation of extension packages',
      missingUninstall: 'Please contact your administrator to enable the removal of extension packages'
    }
  },
  info: {
    clusterStarting:
      'The Databricks cluster for this connection is not started. Please make sure the cluster is ready before being able to run your workflow.'
  }
}
